/**
 * @name: 交易管理-商品可售库存接口文件
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 交易管理-商品可售库存接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {IBatchQty, ISaleQty, ISaleQtyParam} from "@/apis/sale/saleQty/types";

/**
 * 商品库存分页查询
 * @param params
 */
export const productStockQueryByPageApi = (params: ISaleQtyParam) => get<IPageRes<ISaleQty[]>>("/card/productStock/queryByPage", params)
/**
 * 批量修改库存
 * @param data
 */
export const productStockBatchUpdateApi = (data: IBatchQty[]) => postJ("/card/productStock/batchUpdate", data)
