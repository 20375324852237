
/**
 * @name: 交易管理-商品可售库存
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 交易管理-商品可售库存
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import config from "@/config";
import type { ICrudOption } from "@/types/m-ui-crud"
import {productStockBatchUpdateApi, productStockQueryByPageApi} from "@/apis/sale/saleQty";
import {IBatchQty,ISaleQty,ISaleQtyParam} from "@/apis/sale/saleQty/types";

@Component({})
export default class saleQty extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ISaleQty[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ISaleQtyParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: any = {}
  // crud配置信息
  crudOption: ICrudOption = {
    menu: false,
    column: [
      {
        label: "",
        type: "selection",
        width: 50,
        align: "center",
      },
      {
        label: "商品",
        prop: "productKeywords",
        search: true,
        placeholder: "商品ID/商品名称",
        hide: true
      },
      {
        label: "商品ID",
        prop: "productId",
        width: 180,
        align: "center"
      },
      {
        label: "商品名称",
        prop: "productName",
        align: "center",
        overHidden: true
      },
      {
        label: "商品图片",
        prop: "productPic",
        align: "center",
        type: "image",
        imgPrefix: config.downloadUrl
      },
      {
        label: "商品分类",
        prop: "productSortId",
        align: "center",
        search: true,
        type: "select",
        hide: true,
        dicUrl: "/api/card/productSort/dropDown",
        dicFormatter: (res: any) => ({list: res.data, label: 'sortName', value: 'productSortId'}),
      },
      {
        label: "商品分类",
        prop: "productSortName",
        align: "center",
        overHidden: true
      },
      {
        label: "商品售价(元)",
        prop: "productPrice",
        align: "center",
      },
      {
        label: "可售库存",
        prop: "sellableInventory",
        align: "center",
        slot: true
      },
      {
        label: "状态",
        prop: "productStatus",
        align: "center",
        search: true,
        type: "select",
        dicData: [
          {
            label: "上架",
            value: 1
          },
          {
            label: "下架",
            value: 2
          }
        ]
      }
    ]
  }

  selectRows: ISaleQty[] = []
  // 当前编辑id
  openEditId = ''
  // 批量修改可售库存弹窗
  batchDialog = false
  // 批量修改库存数量
  batchQty: string = '0'

  getList () {
    this.tableLoading = true
    productStockQueryByPageApi(this.queryParam).then(e => {
      if (e) {
        this.tableData = e.list
        this.tableTotal = e.total
        this.tableLoading = false
      }
    })
  }

  openEdit (productStockId: string) {
    if (this.openEditId) {
      return
    }
    this.openEditId = productStockId;
  }

  cancelEdit () {
    this.openEditId = ''
    this.getList()
  }

  validQty (qty: string) {
    // 判断是否不为0的无效字符
    if (!qty && isNaN(parseInt(qty))) {
      this.$message.error("可售库存不能为空")
      return false
    }
    // 判断qty是不是整数
    if (parseFloat(qty) % 1 !== 0) {
      this.$message.error("可售库存必须是整数")
      return false
    }
    // 判断负数
    if (parseFloat(qty) < 0) {
      this.$message.error("可售库存不能为负数")
      return false
    }
    if (parseFloat(qty) > 999999) {
      this.$message.error("可售库存不能超过999999")
      return false
    }
    return true
  }

  batchModify (arr: IBatchQty[]) {
    return new Promise((resolve, reject) => {
      productStockBatchUpdateApi(arr).then(e => {
        resolve(null)
      }).catch(() => {
        reject()
      })
    })
  }

  enterEdit (qty: string) {
    if (this.validQty(qty)) {
      this.batchModify([{productStockId: this.openEditId, sellableInventory: parseInt(qty)}]).then(() => {
        this.cancelEdit()
        this.$message.success("操作成功!")
      })
    }
  }

  openBatch () {
    if (!this.selectRows.length) {
      return this.$message.error("请选择需要修改的库存数据")
    }
    this.batchQty = '0'
    this.batchDialog = true
  }

  batchEnter (done: Function, loading: Function) {
    if (this.validQty(this.batchQty)) {
      let arr: IBatchQty[] = this.selectRows.map(item => {
        return {
          productStockId: item.productStockId,
          sellableInventory: parseInt(this.batchQty)
        }
      })
      this.batchModify(arr).then(() => {
        this.cancelEdit()
        this.$message.success("操作成功!")
        done()
      }).finally(() => {
        loading()
      })
    } else {
      loading()
    }
  }

  batchCancel () {

  }

  created () {
    this.getList()
  }
}
